import { BrowserRouter,Route,Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Header from "./Pages/Header";
import Footer from "./Pages/Footer";
import Page from "./Pages/Page";
import Notice from "./Pages/Notice";
import Noticey from "./Pages/Noticey";
import Details from "./Pages/Details";
function App(){
    return(  
            <>
                <BrowserRouter basename={'/app'}>
                <Header />
                    <Routes>
                        <Route exact path="/" element={<Home/>}/>
                        <Route path="Institute" element={<Page title= "Institute" title_id="27"/>}/>
                        <Route path="MBBS" element={<Page title= "MBBS" title_id="32"/>}/>
                        <Route path="MD-MS-DIPLOMA" element={<Page title= "MD/MS and DIPLOMA" title_id="36"/>}/> 
                        <Route path="BDS" element={<Page title= "BDS" title_id="44"/>}/>
                        <Route path="MDS" element={<Page title= "MDS" title_id="45"/>}/>
                        <Route path="BPT" element={<Page title= "BPT" title_id="47"/>}/>
                        <Route path="BSc" element={<Page title= "BSc" title_id="37"/>} />
                        <Route path="MSc" element={<Page title= "MSc" title_id="39"/>}/>
                        <Route path="PBBSc" element={<Page title= "PBBSc" title_id="38"/>}/>
                        <Route path="GNM" element={<Page title= "GNM" title_id="48"/>}/>
                        <Route path="CPS" element={<Page title= "CPS" title_id="56"/>}/>
                        <Route path="BASLP" element={<Page title= "BASLP" title_id="57"/>}/>
                        <Route path="Notice" element={<Notice/>}/>
                        <Route path="Reimbursements" element={<Page title= "Reimbursements" title_id="25"/>}/>
                        <Route exact path="Career" element={<Page title= "Career" title_id="26"/>}/>
                        <Route exact path="RTI" element={<Page title= "RTI" title_id="49"/>}/> 
                        <Route exact path="Rules" element={<Page title= "Rules" title_id="58"/>}/>
                        <Route path="Grievance" element={<Page title= "Grievance" title_id="60"/>}/>
                        <Route path="Tenders" element={<Page title= "Tender" title_id="54"/>}/>
                        <Route path="Notice_23" element={<Noticey title= "Notice Year" title_id="2023"/>}/>
                        <Route path="Notice_22" element={<Noticey title= "Notice Year" title_id="2022"/>}/>
                        <Route path="Notice_21" element={<Noticey title= "Notice Year" title_id="2021"/>}/>
                        <Route path="Notice_20" element={<Noticey title= "Notice Year" title_id="2020"/>}/>
                        <Route path="Notice_19" element={<Noticey title= "Notice Year" title_id="2019"/>}/>
                        <Route path="Notice_18" element={<Noticey title= "Notice Year" title_id="2018"/>}/>
                        <Route path="Details/:value" element={<Details/>}/>
                    </Routes>
                <Footer/>
            </BrowserRouter>    
            </>
    );
}
export default App;