import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getListbyId } from "../Service/NoticeList";

function Details(){
  const [items, setItems] = useState([]);
  let { value } = useParams();
  useEffect(()=>{
    setItems('');
    getListbyId(value).then((result)=>
            setItems(result)
        );
}, [value]);

return <>
    <div className="container">
    <div className="row">
      <div className="span12">
        <div id="page-title">
          <ul className="breadcrumb" style={{ color: "blue", fontSize: "14px" }}>
            <li style={{ color: "red", fontSize: "14px", backgroundColor: "#d6d6d3", font: "italic bold 12px/30px Georgia, serif" }}><a href="index.php" style={{ color: "red" }}>Home</a> <span className="divider">/</span></li>
            <li className="active" style={{ color: "blue", fontSize: "14px", backgroundColor: "#d6d6d3", font: "italic bold 12px/30px Georgia, serif" }}>Notice</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <section id="content">
    <div class="container">
      <div class="row">
        <div class="span12">
          <div class="effect-left"></div>
          <div class="effect-right"></div>
          <div class="page-header1">
            <h2>Notice</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="span12">
          <div class="text-widget4">
            <table class="table">
              <tbody>
              {items.length>0 && items.map(item=>(
                                                    <tr key={item.id}>
                                                    <td>
                                                      <h2 align="center">{item.upload}</h2>
                                                      <h2><div dangerouslySetInnerHTML={{__html: item.title}}></div></h2>
                                                      
                                                    </td>
                                                </tr>

                                                ))}
              
              </tbody>
            </table>

          </div>
        </div>

      </div>
    </div>


  </section>
</>
}
export default Details;