import { Link } from "react-router-dom";
function Footer(){
    return  <>
            <footer>
                <div className="section-3">
                    <div className="container">
                    <div className="row">
                        <div className="span3">
                        <h2>Contact us</h2>
                        <address className="vcard">
                            <span style={{color:"white"}}>For Office Purpose </span>
                            <sapn>cgdme@rediffmail.com</sapn>
                            <span style={{color:"white"}}>For Counselling Purpose</span> 
                            <sapn>cgdme.counseling@gmail.com</sapn>
                            <span className="tel">0771-2972977 (Time: 11 To 17 Hours)</span>
                        </address>
                        </div>
                    
                        <div className="span3">
                        <h2>Useful Links</h2>
                        <ul className="list">
                            <li><Link to="">Contact Us</Link></li>
                        </ul>
                        </div>
                        
                        <div className="span3">
                        <h2>Disclaimer</h2>
                        <div className="clearfix p1">
                            <p className="text-info"><i>All efforts have been made to make the information as accurate as possible. Directorate of Medical Education will not be responsible for any damage caused by inaccuracy in the information available on this website.</i></p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="footer-2">
                    <div className="container">
                    <div className="row">
                        <div className="span12">© 2016 State IT Cell.</div>
                    </div>
                    </div>
                </div>
                </footer>


            </>
}
export default Footer;