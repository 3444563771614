import { Link } from "react-router-dom";

function Notice(){
    return <>
     <div className="container">
                                    <div className="row">
                                    <div className="span12">
                                        <div id="page-title">
                                        <ul className="breadcrumb" style={{color:"blue",fontSize:"14px"}}>
                                            <li style={{color:"red",fontSize:"14px",backgroundColor: "#d6d6d3",font: "italic bold 12px/30px Georgia, serif"}}><a href="index.php" style={{color:"red"}}>Home</a> <span className="divider">/</span></li>
                                            <li className="active"  style={{color:"blue",fontSize:"14px",backgroundColor: "#d6d6d3",font: "italic bold 12px/30px Georgia, serif"}}>Notice</li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
            <section id="content">
            <div class="container">
                <div class="row">
                <div class="span12">
                    <div class="effect-left"></div>
                    <div class="effect-right"></div>
                    <div class="page-header1">
                    <h2>Notice</h2>
                    </div>
                </div>
                </div>
                <div class="row">
            
                <div class="span12">
                    <div class="text-widget4">
                        <Link to="/Notice_23"><h3>2023</h3></Link>	
                        <Link to="/Notice_22"><h3>2022</h3></Link>	    
                        <Link to="/Notice_21"><h3>2021</h3></Link>	    
                        <Link to="/Notice_20"><h3>2020</h3></Link>
                        <Link to="/Notice_19"><h3>2019</h3></Link>
                        <Link to="/Notice_18"><h3>2018</h3></Link>
                    </div>
                </div>
                
                </div>
            </div>
            
            
            </section>
    </>
}
export default Notice;