 export function getList(id){
     return fetch("https://cgdme.in/dme/class/api.php?action=1&menu_id="+id).then((response)=>
        response.json()
        )
 }
 export function getListYear(year){
    return fetch("https://cgdme.in/dme/class/api.php?action=4&year="+year).then((response)=>
       response.json()
       )
}
export function getListbyId(id){
   return fetch("https://cgdme.in/dme/class/api.php?action=5&id="+id).then((response)=>
      response.json()
      )
}