import { Link } from "react-router-dom";
function Header(){

    return  <>
                <div className="header-block clearfix">
                    <header>
                        <div className="container clearfix">
                            <div className="row ">
                                <div className="span12" style={{color:"#FFF",opacity: "0.8",backgroundColor: "rgb(227, 227, 227)"}}>
                                    <img width="90px" height="85px" src="https://cgdme.in/img/logo.png" className="img pull-left" alt="logo" style={{marginTop:"18px"}}/>
                                    <center>
                                        <h1 className="brand brand_"><Link to="" style={{color:"#02779c"}}>Directorate of Medical Education</Link>
                                            <h4 style={{color:"#02779c"}}>Old Nurses Hostel, DKS Bhawan Parisar, Raipur (CG)</h4>
                                        </h1>
                                    </center>
                                    <div className="clear"></div>
                                    <div className="row-nav-search">
                                        <div className="navbar navbar_">
                                            <div className="container">
                                                <div className="nav-collapse nav-collapse_ collapse" >
                                                    <ul className="nav sf-menu">
                                                        <li className="sub-menu"><Link to="/">HOME</Link></li>
                                                        <li className="sub-menu"><Link to="">COUNSELLING</Link>
                                                            <ul className="sub-menu">
                                                                <li className=""><Link to="#">MEDICAL</Link>
                                                                    <ul className="sub-menu">
                                                                        <li className=""><Link to="MBBS">MBBS</Link></li>
                                                                        <li className=""><Link to="MD-MS-DIPLOMA">MD/MS DIPLOMA</Link></li>
                                                                    </ul>
                                                                </li>
                                                                <li><Link to="#">DENTAL</Link>
                                                                    <ul className="sub-menu">
                                                                        <li><Link to="BDS">BDS</Link></li>
                                                                        <li><Link to="MDS">MDS</Link></li>
                                                                    </ul>
                                                                </li>'
                                                                <li><Link to="#">PHYSIOTHERAPY</Link>
                                                                    <ul className="sub-menu">
                                                                        <li><Link to="BPT">BPT</Link></li>
                                                                    </ul>
                                                                </li>
                                                                <li className=""><Link to="#">NURSING</Link>
                                                                    <ul className="sub-menu">
                                                                        <li><Link to="BSc">BSc Nursing</Link></li>
                                                                        <li><Link to="PBBSc">Post Basic BSc Nursing</Link></li>
                                                                        <li><Link to="MSc">MSc Nursing</Link></li>
                                                                        <li><Link to="GNM">General Nursing and Midwifery(GNM)</Link></li>
                                                                    </ul>
                                                                </li>
                                                                <li><Link to="CPS">CPS</Link></li>
                                                                <li><Link to="BASLP">BASLP</Link></li>
                                                            </ul>

                                                        </li>
                                                        <li><Link to="Institute">INSTITUTES</Link></li>
                                                        <li><Link to="Notice">NOTICE</Link></li>
                                                        <li><Link to="REIMBURSEMENTS">REIMBURSEMENTS</Link></li>
                                                        <li><Link to="Career">VACANCY</Link></li>
                                                        <li><Link to="RTI">RTI</Link></li>
                                                        <li><Link to="Rules">RULES</Link></li>
                                                        <li><Link to="Grievance">Grievance</Link></li>
                                                        <li><Link to="Tenders">Tenders</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </header>
                </div>
            </>
}
export default Header;