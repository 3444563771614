import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
function Home(){
    const [items, setItems] = useState([]);
    useEffect(()=>{
        fetch("https://cgdme.in/dme/class/api.php?action=3").then((response)=>
        response.json()
        ).then((result)=>
                setItems(result)
            );
    }, []);
    return  <>
                <div className="container">
                    <div className="span12" style={{marginTop:"-1%",marginBottom:"-4.5%",marginLeft: "auto"}}>
                        <div id="page-title">
                            <ul className="breadcrumb" style={{color:"blue",fontSize:"14px",display:"none"}}>
                                <li style={{color:"red",fontSize:"14px",backgroundColor: "#d6d6d3",font: "italic bold 12px/30px Georgia, serif"}}><a href="index.php" style={{color:"red"}}>Home</a> <span class="divider">/</span></li>
                                <li className="active"  style={{color:"blue",fontSize:"14px",backgroundColor: "#d6d6d3",font: "italic bold 12px/30px Georgia, serif"}}>Home</li>
                            </ul>
                                <marquee behavior="scroll" direction="left" scrollamount="5" class="alert alert-success" style={{background:"rgb(0,93,100)",color:"#ffffff",fontWeight:"bold"}}>
                                <img src="img/NewAlt.gif" alt="" width="30px" />&nbsp;<a href="https://drive.google.com/file/d/1dhPUn9arl1zhXvTky4th3tV4FoFoH5lM/view" style={{color:"#ffffff"}}>14 Jan 2023 : प्रवेश वर्ष 2022 फिजियोथेरपी पाठ्यक्रम (BPT) मॉपअप राउण्ड आबंटन सूचना</a></marquee>
                        
                        <br/>
                        </div>
                    </div>
                </div>

        <section id="content">
            <div className="container">
                <div className="row">

                    <div className="span10">
                        <div className="text-widget2">
                            <table className="table tblnotice"><tbody>
                            {items.length===0 &&
                                                    <tr key="0">
                                                    <td>Record Not Found</td>
                                                </tr>
                                                }
                                                {items.length>0 && items.map(item=>(
                                                    <tr key={item.id}>
                                                    <td>{item.upload}<Link to={`/Details/${ item.id }`} style={{color:"rgb(240, 233, 83)",fontSize:"15px"}}>.....More</Link></td>
                                                    {/* <td>Item Id : {item.id}</td> */}
                                                </tr>

                                                ))}


                            </tbody></table>
                            <Link className="btn btn-warning btn-block" to="Notice" style={{marginLeft: "-9px"}}>Read More...</Link>
                        </div>
                    </div>
                    <div className="span1 text-widget3" style={{backgroundColor : "rgb(56,54,41)"}}>
                        <div className="col-lg-3 col-md-3 col-sm-6  text-center doc-item">
                            <div className="common-doctor animated fadeInUp clearfix ae-animation-fadeInUp" align="center">
                                <figure>
                                    <img width="200" src="img/scrty2.jpg" alt="doctor-2" />
                                </figure>
                                <div className="text-content" align="center">
                                    <h6 style={{color:"#FFF"}}>	Shri C.R. Prasanna (I.A.S.) - Principal Secretary</h6>
                                    <div style={{color:"#FFF",fontSize:"11px",lineHeight:"15px"}}>Govt. of Chhattisgarh</div>
                                    <div style={{color:"#FFF",fontSize:"11px",lineHeight:"15px"}}>Department of Health & Family Welfare & Medical Education</div>
                                </div>
                            </div>
                        </div>
                        <div className="common-doctor animated fadeInUp clearfix ae-animation-fadeInUp" align="center">
                            <figure>
                                <img width="200" height="300px" src="img/DME.jpg" alt="" />                             </figure>
                            <div className="text-content" align="center">
                                <h6 style={{color:"#FFF"}}>Dr. VISHNU DUTT</h6>
                                <div style={{color:"#FFF",fontSize:"11px",lineHeight:"15px"}}> Director of Medical Education</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            </>
}
export default Home;