import { useEffect, useState } from "react";
import { getList } from "../Service/NoticeList";
import { Link } from "react-router-dom";

function Page(prop){
    const [items, setItems] = useState([]);
    const title = prop.title;
    useEffect(()=>{
        setItems('');
        getList(prop.title_id).then((result)=>
                setItems(result)
            );
    }, [prop.title_id]);
        return (
        <>
                    <div className="container">
                                    <div className="row">
                                    <div className="span12">
                                        <div id="page-title">
                                        <ul className="breadcrumb" style={{color:"blue",fontSize:"14px"}}>
                                            <li style={{color:"red",fontSize:"14px",backgroundColor: "#d6d6d3",font: "italic bold 12px/30px Georgia, serif"}}><a href="index.php" style={{color:"red"}}>Home</a> <span className="divider">/</span></li>
                                            <li className="active"  style={{color:"blue",fontSize:"14px",backgroundColor: "#d6d6d3",font: "italic bold 12px/30px Georgia, serif"}}>{title}</li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <section id="content">
                    <div className="container">
                        <div className="row">
                            <div className="span12">
                                <div className="effect-left"></div>
                                <div className="effect-right"></div>
                                <div className="page-header1">
                                    <h2>{title}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="span9">
                                <div className="tab-content">
                                    <div id="Notice" className="tab-pane fade in active">
                                        <div className="text-widget2">
                                            <table className="table tblnotice">
                                                <tbody>
                                                {items.length===0 &&
                                                    <tr key="0">
                                                    <td>Record Not Found</td>
                                                </tr>
                                                }
                                                {items.length>0 && items.map(item=>(
                                                     <tr key={item.id}>
                                                     <td>{item.upload}<Link to={`/Details/${ item.id  }`} style={{color:"rgb(240, 233, 83)",fontSize:"15px"}}>.....More</Link></td>
                                                     {/* <td>Item Id : {item.id}</td> */}
                                                </tr>

                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </section>
                </>
)}
export default Page;